import './spin.css';

function FastSpin(){
    return(
        <>


        <div>
            <div className='spinner'>

            </div>
            <p className='sam_abs' style={{color:'green', fontSize:'15px', wordSpacing:'1px'}}>Please wait...</p>

        </div>


        </>
    )
};

export default FastSpin;